import React from "react"
import { Avatar, Container } from "@chakra-ui/react"
import ZiptiText from "../zipti_text"
import ZiptiButton from "../zipti_button"

export interface VideoViewModalProps {
  videoSrc: string | undefined
  profileImageUrl?: string | undefined
  headerCopy: string
}

const VideoViewModal: React.FC<VideoViewModalProps> = (
  props: VideoViewModalProps
) => {
  return (
    <Container
      centerContent
      position="relative"
      paddingInlineStart="0rem"
      paddingInlineEnd="0rem"
      bgColor="white"
    >
      <video
        // @ts-ignore
        position="absolute"
        top="0"
        height="60vh"
        width="100%"
        autoPlay
        playsInline
        loop
        controls
        src={props.videoSrc}
      />
      <Avatar
        src={props.profileImageUrl}
        position="absolute"
        top="55vh"
        h="10vh"
        w="10vh"
        borderRadius="full"
        borderColor="#4329AE"
        borderStyle="solid"
        borderWidth="3px"
      />
      <ZiptiText
        position="absolute"
        top="67vh"
        fontSize="2.5vh"
        fontWeight="bold"
      >
        {props.headerCopy}
      </ZiptiText>
    </Container>
  )
}

export default VideoViewModal
