import { Container } from "@chakra-ui/layout"
import React from "react"
import ZiptiButton from "../zipti_button"
import ZiptiText from "../zipti_text"

export interface ButtonPromptModalProps {
  promptText: string
  buttonText: string
  onButtonClick: any
}

const ButtonPromptModal: React.FC<ButtonPromptModalProps> = (
  props: ButtonPromptModalProps
) => {
  return (
    <Container centerContent position="relative">
      <ZiptiText position="absolute" top="10vh">
        {props.promptText}
      </ZiptiText>
      <ZiptiButton
        position="absolute"
        top="20vh"
        left="30vw"
        onClick={props.onButtonClick}
      >
        {props.buttonText}
      </ZiptiButton>
    </Container>
  )
}

export default ButtonPromptModal
