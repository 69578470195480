import { Avatar } from "@chakra-ui/avatar"
import { Image } from "@chakra-ui/image"
import { Box } from "@chakra-ui/layout"
import React from "react"

import playTriangleIcon from "../../images/playTriangle.svg"
import ZiptiText from "../shared/zipti_text"

interface AnswerDecisionMessageProps {
  timeSinceCopy: string | undefined
  videoThumbnailSrc: string | undefined
  mt: string
  onVideoThumbnailClick: Function
  profilePhotoSrc: string | undefined
}

const AnswerDecisionMessage: React.FC<AnswerDecisionMessageProps> = props => {
  return (
    <Box
      mt={!!props.mt ? props.mt : 0}
      w="100vw"
      h={{ base: "40vh", md: "55vh" }}
      position="relative"
    >
      <Avatar
        position="absolute"
        left="5vw"
        h={{ base: "5vh", md: "10vh" }}
        w={{ base: "10vw", md: "7vw" }}
        boxShadow="0 2px 10px 0 rgba(0,0,0,0,20)"
        borderRadius="100px 100px 1px 100px"
        src={props.profilePhotoSrc}
      />
      <Image
        position="absolute"
        left="17vw"
        h={{ base: "30vh", md: "50vh" }}
        w={{ base: "40vw", md: "30vw" }}
        borderRadius="md"
        src={props.videoThumbnailSrc}
        cursor="pointer"
        onClick={() => {
          props.onVideoThumbnailClick()
        }}
      />
      <Image
        position="absolute"
        top={{ base: "25vh", md: "45vh" }}
        left={{ base: "22vw", md: "31.5vw" }}
        w="3vh"
        h="3vh"
        src={playTriangleIcon}
      />
      <ZiptiText
        position="absolute"
        fontSize="1.5vh"
        top={{ base: "31vh", md: "52vh" }}
        left={{ base: "22vw", md: "17.5vw" }}
        w="30vw"
        color="gray.400"
      >
        {props.timeSinceCopy}
      </ZiptiText>
    </Box>
  )
}

export default AnswerDecisionMessage
