import { Box, Container } from "@chakra-ui/layout"
import React, { useState } from "react"
import Wrapper from "../shared/wrapper"
import ZiptiText from "../shared/zipti_text"
import paidIcon from "../../images/paidIcon.svg"
import declineIcon from "../../images/declineIcon.svg"
import clarifyIcon from "../../images/clarifyIcon.svg"
import { validate as uuidValidate } from "uuid"
import { Image } from "@chakra-ui/image"
import AnswerDecisionMessage from "./answer_decision_message"
import ZiptiButton from "../shared/zipti_button"
import { useDisclosure } from "@chakra-ui/hooks"
import VideoPromptModal from "../shared/modal/video_prompt_modal"
import ButtonPromptModal from "../shared/modal/button_prompt_modal"
import VideoPreviewModal from "../shared/modal/video_preview_modal"
import VideoViewModal from "../shared/modal/video_view_modal"
import { navigate } from "gatsby-link"
import ZiptiModal from "../shared/zipti_modal"
import ZiptiDrawer from "../shared/zipti_drawer"
import { useEffect } from "react"
import { useContext } from "react"
import { ApiContext } from "../user_wrapper"
import { Ask, AskAccountInfo, FileUploadType } from "../../services/api_types"
import { uploadFile } from "../../services/api_utils"
import moment from "moment"
import "moment-timezone"
import { Divider, HStack, VStack } from "@chakra-ui/react"

const AnswerDecision = props => {
  const MODAL_TYPES = {
    DECLINE: "decline",
    ANSWER: "answer",
    CLARIFY: "clarify",
    REDO: "redo",
  }
  const DRAWER_TYPES = {
    ANSWER_PREVIEW: "answer_preview",
    CLARIFY_PREVIEW: "clarify_preview",
    ASK_VIEW: "ask_view",
  }
  const [ask, setAsk] = useState<Ask | undefined>(undefined)
  const [askAccountInfo, setAskAccountInfo] = useState<
    AskAccountInfo | undefined
  >(undefined)
  const [modalType, setModalType] = useState(MODAL_TYPES.ANSWER)
  const timezone = moment.tz.guess()
  const [drawerType, setDrawerType] = useState(DRAWER_TYPES.ANSWER_PREVIEW)
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [profileName, setProfileName] = useState<string | undefined>("")
  const [profileImage, setProfileImage] = useState<string | undefined>("")
  const [askDate, setAskDate] = useState<string | undefined>("")
  const [videoThumbnail, setVideoThumbnail] = useState<string | undefined>("")
  const [askPrice, setAskPrice] = useState<number | undefined>(undefined)
  const [videoFromAsk, setVideoFromAsk] = useState<string | undefined>("")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const onDrawerOpen = () => {
    setIsDrawerOpen(true)
  }
  const onDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const apiClient = useContext(ApiContext)

  const answerDecisionUseEffect = async () => {
    if (typeof window !== "undefined") {
      const splitHref = window.location.href.split("/")
      const indexOfAnswerDecision = splitHref.findIndex(
        element => element === "answer_decision"
      )
      if (indexOfAnswerDecision === splitHref.length - 1) {
        alert("no ask ID")
        return
      }

      const askId = splitHref[indexOfAnswerDecision + 1]

      if (!uuidValidate(askId)) {
        alert(`invalid ask id: ${askId}`)
        return
      }

      const askRes = await apiClient.getAsk(askId)
      if (!!askRes.error || !askRes.data) {
        console.log("error fetching ask", askRes.error)
        return
      }

      setAskPrice(askRes.data.ask.price)
      setAskDate(askRes.data.ask.created_at)

      const accountRes = await apiClient.getAccountById(
        askRes.data.ask.account_id
      )
      console.log("ACCOUNTRES", accountRes, askRes.data.ask.account_id)
      setProfileName(accountRes.data?.account.name)
      setProfileImage(accountRes.data?.account?.profile_image_url)
      setVideoThumbnail(askRes.data.ask.account_ask_video_thumbnail_url)

      setAsk(askRes.data?.ask)

      const askAccountInfoRes = await apiClient.getAskAccountInfo(askId)
      if (!!askAccountInfoRes.error || !askAccountInfoRes.data) {
        console.log("error fetching ask account info", askAccountInfoRes.error)
        return
      }

      setAskAccountInfo(askAccountInfoRes.data)
      setVideoFromAsk(askRes.data.ask.account_ask_video_url)
      // TODO do stuff with askId
      // namely
      //  allow playing of video on click of thumbnail
      //  actual actions using askId
    }
  }

  useEffect(() => {
    answerDecisionUseEffect()
  }, [])

  const answerPromptText = `Yay! You're ready to answer ${profileName}'s question. If the video is longer than 5 minutes you will not be able to send it.`
  const clarifyPromptText =
    "Need some clarification? Let's record your question! If the video is longer than 5 minutes you will not be able to send it."

  const renderModal = _ => {
    switch (modalType) {
      case MODAL_TYPES.ANSWER:
        return (
          <VideoPromptModal
            promptText={answerPromptText}
            buttonText={"Answer"}
            handleVideoFile={handleAnswerVideoFile}
          />
        )
      case MODAL_TYPES.CLARIFY:
        return (
          <VideoPromptModal
            promptText={clarifyPromptText}
            buttonText={"Clarify"}
            handleVideoFile={handleClarifyVideoFile}
          />
        )
      case MODAL_TYPES.DECLINE:
        return (
          <ButtonPromptModal
            promptText={"Do you really want to discard your video?"}
            buttonText={"Decline"}
            onButtonClick={handleDecline}
          />
        )
      case MODAL_TYPES.REDO:
        return (
          <VideoPromptModal
            promptText={"Would you like to redo your video?"}
            buttonText={"Redo"}
            handleVideoFile={handleAnswerVideoFile}
          />
        )
    }
  }

  const nameOfAsker = profileName
  const answerPreviewHeaderCopy = `Answering ${nameOfAsker}`
  const answerPreviewDetailCopy = "Tap Save to submit your answer."
  const answerPreviewButtonCopy = "Save"
  const answerPreviewFooterCopy = "Redo"
  const clarifyPreviewHeaderCopy = `Ask Clarifying Question`
  const clarifyPreviewDetailCopy = "Tap Save to submit your question."
  const clarifyPreviewButtonCopy = "Save"
  const clarifyPreviewFooterCopy = "Redo"

  const handleAnswer = () => {
    navigate(`/answer/${ask?.ask_id}`)
  }

  const handleClarify = _ => {
    navigate("/home")
  }

  const handleRedoAnswer = _ => {
    setVideoSrc(undefined)
    setModalType(MODAL_TYPES.REDO)
    onDrawerClose()
    onOpen()
  }

  const handleRedoClarify = _ => {
    setVideoSrc(undefined)
    setModalType(MODAL_TYPES.CLARIFY)
    onDrawerClose()
    onOpen()
  }

  const renderDrawer = _ => {
    switch (drawerType) {
      case DRAWER_TYPES.ANSWER_PREVIEW:
        return (
          <VideoPreviewModal
            videoSrc={videoSrc}
            headerCopy={answerPreviewHeaderCopy}
            detailCopy={answerPreviewDetailCopy}
            buttonCopy={answerPreviewButtonCopy}
            footerCopy={answerPreviewFooterCopy}
            onClickFinish={handleAnswer}
            onClickRedo={handleRedoAnswer}
          />
        )
      case DRAWER_TYPES.CLARIFY_PREVIEW:
        return (
          <VideoPreviewModal
            videoSrc={videoSrc}
            headerCopy={clarifyPreviewHeaderCopy}
            detailCopy={clarifyPreviewDetailCopy}
            buttonCopy={clarifyPreviewButtonCopy}
            footerCopy={clarifyPreviewFooterCopy}
            onClickFinish={handleClarify}
            onClickRedo={handleRedoClarify}
          />
        )
      case DRAWER_TYPES.ASK_VIEW:
        return (
          <VideoViewModal
            videoSrc={videoFromAsk}
            headerCopy={`Video from ${profileName}`}
          />
        )
    }
  }

  const handleAnswerVideoFile = async (videoFile: File) => {
    // TODO setup with our new upload system
    if (!ask) {
      console.log("no ask to submit video to")
      return
    }
    const video_url = await uploadFile(
      apiClient,
      videoFile,
      FileUploadType.SUBMIT_ANSWER_VIDEO,
      ask.ask_id
    )

    if (!video_url) {
      console.log("issue uploading video url")
      return
    }

    const res = await apiClient.submitAnswerVideo({ video_url }, ask?.ask_id)
    if (!!res.error) {
      console.log("issue uploading answer", res.error)
    }

    setVideoSrc(video_url)
    setDrawerType(DRAWER_TYPES.ANSWER_PREVIEW)
    onDrawerOpen()
    onClose()
  }

  const handleViewAskVideo = () => {
    setVideoSrc(videoFromAsk)
    setDrawerType(DRAWER_TYPES.ASK_VIEW)
    onDrawerOpen()
  }

  const handleClarifyVideoFile = async (videoFile: File) => {
    if (!ask) {
      console.log("no ask to submit video to")
      return
    }
    const video_url = await uploadFile(
      apiClient,
      videoFile,
      FileUploadType.SUBMIT_CLARIFYING_QUESTION_VIDEO,
      ask.ask_id
    )

    if (!video_url) {
      console.log("issue uploading video url")
      return
    }

    const res = await apiClient.submitClarifyingQuestionVideo(
      { video_url },
      ask?.ask_id
    )
    if (!!res.error) {
      console.log("issue uploading answer", res.error)
    }
    setVideoSrc(video_url)
    setDrawerType(DRAWER_TYPES.CLARIFY_PREVIEW)
    onDrawerOpen()
    onClose()
  }

  const handleDecline = async () => {
    if (!ask) {
      return
    }
    const result = await apiClient.declineAsk(ask?.ask_id)

    console.log("issue declining ask", result.error)

    navigate("/home")
  }

  const navigateToModalType = modalType => {
    setModalType(modalType)
    onOpen()
  }

  const navigateToDeclineModal = _ => {
    navigateToModalType(MODAL_TYPES.DECLINE)
  }

  const navigateToAnswerModal = _ => {
    if (videoSrc !== undefined && drawerType === DRAWER_TYPES.ANSWER_PREVIEW) {
      onDrawerOpen()
      return
    }
    navigateToModalType(MODAL_TYPES.ANSWER)
  }

  const navigateToClarifyModal = _ => {
    if (videoSrc !== undefined && drawerType === DRAWER_TYPES.CLARIFY_PREVIEW) {
      onDrawerOpen()
      return
    }
    navigateToModalType(MODAL_TYPES.CLARIFY)
  }

  return (
    <Wrapper>
      <ZiptiText mt="3vh" fontWeight="bold">
        {profileName}
      </ZiptiText>
      <Container mt="4vh" centerContent={true}>
        <Image h="3vh" w="3vh" src={paidIcon} />
        <ZiptiText fontWeight="bold" fontSize="1.5vh" color="black">
          {profileName} wants to pay you ${askPrice}
        </ZiptiText>
        <ZiptiText fontSize="1.25vh" color="gray.400">
          {moment(askDate).format("dddd, MMMM Do YYYY")}
        </ZiptiText>
      </Container>
      <AnswerDecisionMessage
        videoThumbnailSrc={videoThumbnail}
        mt="5vh"
        timeSinceCopy={moment.utc(ask?.created_at).tz(timezone).fromNow()}
        onVideoThumbnailClick={handleViewAskVideo}
        profilePhotoSrc={profileImage}
      />

      <Box
        paddingInline={{ base: 0 }}
        position="fixed"
        bottom="0px"
        w="100%"
        h="10vh"
        bgColor="white"
      >
        <Divider borderTopWidth="0.25vh" />
        <HStack
          paddingInline={{ base: "2rem", md: "25%" }}
          justifyContent="space-between"
          w="100%"
          h="100%"
        >
          <VStack>
            {" "}
            <Image
              src={declineIcon}
              h="6vh"
              w="6vh"
              cursor="pointer"
              onClick={navigateToDeclineModal}
            />
            <ZiptiText
              mt="-2rem"
              fontWeight="bold"
              fontSize="1vh"
              color="gray.400"
            >
              Decline
            </ZiptiText>
          </VStack>

          <ZiptiButton onClick={navigateToAnswerModal}>Answer</ZiptiButton>
          <VStack>
            <Image
              src={clarifyIcon}
              h="6vh"
              w="6vh"
              cursor="pointer"
              onClick={navigateToClarifyModal}
            />
            <ZiptiText fontWeight="bold" fontSize="1vh" color="gray.400">
              Clarify
            </ZiptiText>
          </VStack>
        </HStack>
      </Box>
      <ZiptiModal isOpen={isOpen} onClose={onClose} renderModal={renderModal} />
      <ZiptiDrawer
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        renderDrawer={renderDrawer}
      />
    </Wrapper>
  )
}

export default AnswerDecision
